import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PandemicInformation = makeShortcode("PandemicInformation");
const SingleImage = makeShortcode("SingleImage");
const TextCustom = makeShortcode("TextCustom");
const BasicAudio = makeShortcode("BasicAudio");
const ButtonDialog = makeShortcode("ButtonDialog");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "conflict-with-your-partner-or-your-teens-other-parent-leave-your-teenager-out-of-it"
    }}>{`Conflict with your partner or your teen’s other parent? Leave your teenager out of it`}</h1>
    <p>{`Managing conflict with your partner or your teen’s other parent is just as important as managing conflict with your teenager. Even if you have separated from your teen’s other parent, it’s important for you to manage conflict in order to protect your teen’s mental health.`}</p>
    <p>{`When you have disagreements with your partner or your teen’s other parent...`}</p>
    <h3 {...{
      "id": "do-not-ask-your-teenager-to-choose-sides"
    }}>{`Do not ask your teenager to choose sides.`}</h3>
    <p>{`It’s important for your teenager to have a positive relationship with both parents. Asking them to choose sides makes this difficult for them.`}</p>
    <h3 {...{
      "id": "do-not-bad-mouth-them-to-your-teenager"
    }}>{`Do not bad mouth them to your teenager.`}</h3>
    <p>{`If you get frustrated with your partner or your teen’s other parent, it’s better to say which specific behaviour is upsetting you rather than criticising them in a personal way.`}</p>
    <h3 {...{
      "id": "find-a-private-place-away-from-your-teenager"
    }}>{`Find a private place away from your teenager.`}</h3>
    <p>{`Seeing frequent and intense conflict between parents is upsetting for teenagers.`}</p>
    <PandemicInformation mdxType="PandemicInformation">
  <h3>Try to be on the same page about parenting during the pandemic.</h3>
    </PandemicInformation>
    <p>{`Communicate regularly with your partner or teen’s other parent about your parenting approach during the pandemic (e.g. expectations for your teen about following the local restrictions). Discuss expectations about sharing parenting responsibilities, including how this might change if local restrictions change. `}</p>
    <SingleImage smallGridSize={8} gridSize={6} src="/images/m5/20.svg" alt="child on tightrope" mdxType="SingleImage" />
    <p>{`Consider seeking support from a trusted friend or professional if you are struggling to manage conflict or family relationships, including conflict with your teen, your partner, or co-parent. `}</p>
    <PandemicInformation mdxType="PandemicInformation">
  This is especially important if you’ve noticed increased conflict during the pandemic. Managing your conflicts well is important not only for your own well-being but also for your teenager’s.
    </PandemicInformation>
    <TextCustom customColor="blue" mdxType="TextCustom">
  <b>
    <i>How do you feel when your parents argue?</i> Hear what Sinh, 13 years, says…
  </b>
    </TextCustom>
    <BasicAudio src="/audios/module_5_audio_clip.mp3" mdxType="BasicAudio" />
    <ButtonDialog linkText="Read Transcript" title="How do you feel when your parents argue?" mdxType="ButtonDialog">
  <p>Sinh, 13 years, says…</p>
  <p>
    My parents mostly argue when I’m asleep, so it wakes me up. Not only does it wake me up, but then I think about it
    all day at school the next day.
  </p>
  <p>
    The next day I can’t focus, I feel stressed and worried and I feel like I've caused the argument even if they tell
    me I haven’t. I'm not as lively the next day at school, as I don’t have the energy. I'm drained from the night
    before and I feel like the argument is lingering in my head.
  </p>
  <p>
    If I'm up when they are arguing, they tend to ignore me so I tell them to “Shut up, stop yelling and stop involving
    the whole household with their fighting!”.
  </p>
  <p>I usually walk away into my bedroom and listen to music. Somehow, they make me feel bad if they are arguing.</p>
    </ButtonDialog>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      